.mm-select-exchange-field-item {
    @apply p-2 text-sm flex flex-row items-center gap-2;

    &._selected {
        @apply text-main-purple;
    }

    .exchange-image {
        width: 20px;
        height: 20px;
    }
}