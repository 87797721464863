table.mm-common-table {
  @apply w-full;

  td,
  th {
    @apply py-2 px-4;
  }

  tr._is_selected {
    background-color: rgba(240, 240, 255, 0.4);
  }

  thead {
    @apply sticky top-0 left-0 bg-white;

    tr {
      th {
        @apply text-gray-1 font-normal;
        font-size: 13px;
      }
    }

    div.checkbox-area {
      @apply flex flex-row items-center gap-2 text-gray-1 font-normal;
      font-size: 13px;
    }
  }

  tbody {
    div.checkbox-area {
      @apply flex flex-row items-center gap-2 text-black font-normal;
      font-size: 13px;
    }
  }
}

table.mm-common-table {
  tr.mm-common-table__total-row {
    @apply sticky bottom-0 bg-white;
  }

  td {
    font-size: 13px;
  }

  th.mm-common-table__total-row__title {
    @apply font-bold text-main-purple;
    font-size: 13px;
  }

  th.mm-common-table__total-row__value {
    @apply font-bold;
    font-size: 13px;
  }
}

.mm-common-table-no-info {
  @apply w-full p-4 text-sm text-gray-1;
}
