.mm-pair-charts-holder {
    height: calc(100vh - 120px);
    width: 100%;
    
    .mm-pair-chart-section {
        @apply w-full flex flex-col items-center gap-2;
    
        &.mm-pair-chart__pair {
            height: calc(60% - 1rem);
        }

        &.mm-pair-chart__balance {
            height: calc(40% - 1rem);
        }

        &._not-found {
            height: 100px;
        }

        &._is-single_pair {
            height: calc(100% - 1rem - 100px);
            margin-bottom: 1rem;
        }

        &._is-single_balances {
            height: calc(100% - 1rem - 100px);
            margin-top: 1rem;
        }

        &._is-double_not-found {
            margin: 0.5rem 0;
            height: calc(50% - 1rem);
        }
    }

    .mm-pair-chart-container {
        position: relative;
        width: 100%;
        height: 100%;

        .chart {
            width: 100%;
            height: 100%;
        }
    }
}