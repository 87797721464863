.mm-wt-bot-chart-wrapper {
  @apply w-full flex flex-col gap-4;

  &__toolbar {
    @apply w-full flex items-center gap-4;
  }

  .chart {
    @apply w-full relative;
    height: 400px;
  }

  .pair-chart {
    @apply w-full h-full;
  }

  .chart__error {
    @apply absolute flex items-center justify-center flex-col gap-2 text-center p-5 rounded-xl z-50;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: rgba(235, 202, 202, 0.9);

    &__title {
      @apply text-2xl font-semibold;
    }

    &__subtitle {
      @apply text-base font-medium;
      max-width: calc(100% - 30px);
      word-wrap: break-word;
    }
  }

  .chart__loading {
    @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full flex flex-row items-center justify-center gap-2;
    padding: 24px;

    &__title {
        @apply text-main-purple text-base font-medium;
    }
  }

  .chart-tooltip {
    @apply absolute top-1 left-1 flex flex-col gap-1 items-start;
    font-size: 13px;
    z-index: 100;

    .tooltip-header {
        @apply flex flex-row items-center gap-4 py-1 px-2 rounded-sm;
        background-color: #eeeeeec6;
    }

    .tooltip-title {
        @apply text-sm font-medium text-slate-900;
    }

    .pair-values {
        @apply flex flex-row items-center gap-2 rounded-sm;
        padding: 2px 4px;
        background: #f0f0f0cb;
    }

    div.value {
        @apply font-monospace;
        display: inline;
    }

    div.volume-value {
        @apply font-monospace flex;
        display: inline;
        font-size: 12px;
    }

    .round {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
    }

    .caption {
        @apply text-slate-800 font-monospace;
    }

    .amount {
        @apply font-monospace font-medium;
        margin-left: 1px;
        letter-spacing: -0.4px;

        &.green {
            color: rgb(14, 203, 129);
        }

        &.red {
            @apply text-red-700;
        }
    }
}

  .chart-summary {
    @apply w-full grid rounded-2xl gap-x-6 gap-y-3 p-5;
    background-color: rgba(240, 240, 255, 0.4);
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;

    .summary-section {
      @apply w-full flex items-center justify-between gap-2;

      span {
        font-size: 13px;
        line-height: 17px;
      }
    }
  }

  .chart-summary__loader {
    @apply w-full flex items-center justify-center rounded-2xl;
    height: 90px;
    background-color: rgba(240, 240, 255, 0.4);
  }

  .chart-summary__error {
    @apply w-full flex items-center justify-center rounded-2xl;
    height: 90px;
    background-color: rgba(174, 42, 42, 0.248);

    &__title {
      @apply text-red-1 text-base font-medium;
    }
  }
}
