.mm-alert-modal {
  @apply bg-white rounded-3xl p-7 flex flex-col gap-7 items-center;
  box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);

  width: 50%;
  min-width: 500px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;

  &__header {
    @apply w-full flex flex-row items-center justify-between relative;

    h3 {
      @apply w-full text-center text-xl;

      &._success {
        @apply text-main-purple;
      }

      &._failed {
        @apply text-red-1;
      }
    }

    &__close-button {
      @apply cursor-pointer absolute top-0 right-0;
    }
  }

  &__text {
    @apply text-gray-1;
    font-size: 15px;
  }

  &__sure-actions {
    @apply w-full flex flex-col;
  }
}
