.mm-auto-refresh {
  button.refresh {
    padding: 8px;
    border-radius: 5px;
    outline: none;

    svg {
      width: 20px;
      height: 20px;
    }

    &:not(._active) {
      svg {
        @apply fill-gray-500;
      }
    }

    &._active {
      svg {
        @apply fill-main-purple;
      }
    }

    &._loading {
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      svg {
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-duration: 0.75s;
      }
    }
  }
}
