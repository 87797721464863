.mm-common-spinner {
  border-radius: 50%;
  border-color: #f7f7f7 !important;
  border-right-color: #5932ea !important;
  animation: spinner-rotation-keyframes 1s infinite linear;

  &-mini {
    width: 24px;
    height: 24px;
    border: 3.8px solid;
  }

  &-small {
    width: 40px;
    height: 40px;
    border: 6.4px solid;
  }

  &-medium {
    width: 56px;
    height: 56px;
    border: 9px solid;
  }

  &-large {
    width: 72px;
    height: 72px;
    border: 11.5px solid;
  }
}

@keyframes spinner-rotation-keyframes {
  to {
    transform: rotate(1turn);
  }
}
