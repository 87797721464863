.mm-add-pair-modal {
  @apply w-full flex flex-col gap-5;
  min-width: 500px;

  &__token-selector {
    @apply w-full;

    &__pair {
      @apply w-full flex flex-col items-center;

      .mm-dropdown {
        @apply mb-5
      }
    }

    .swap-tokens-button {
      margin-top: 10px;
      margin-bottom: -14px;
      position: relative;
      z-index: 1;
    }
  }

  &__fee-selector {
    @apply w-full flex flex-row items-center justify-between;

    &__text {
      @apply text-gray-1 font-bold;
      font-size: 15px;
    }
  }

  &__notes {
    @apply w-full;
  }

  &__error {
    @apply text-red-1 font-bold;
    font-size: 15px;
  }
}
