.scrollable {
  &::-webkit-scrollbar {
    @apply w-[5px] h-[5px] rounded;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent rounded;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-main-purple rounded;
  }
}
