.mm-toggle-radio {
  input {
    display: none;
  }

  @apply bg-light-gray-1 rounded-3xl grid auto-cols-auto grid-flow-col p-0.5;

  &__text {
    @apply text-gray-2 py-1.5 cursor-pointer rounded-3xl;
    text-align: center;
  }

  &__text--selected {
    @apply bg-main-purple text-white cursor-default;
  }

  &__text--disabled {
    @apply bg-gray-2 text-white cursor-not-allowed;
  }
}
