.fill-the-gaps {
  @apply p-5 flex flex-col;

    &__inputs {
      @apply flex gap-6 pb-3;

      &__time-frame-container {
       width: 30%;
      }
      &__amount-container {
        @apply flex flex-col rounded-2xl p-4  bg-hover-light gap-4;
        width: 70%;

        &__checkbox {
          @apply flex items-center gap-2 text-black text-base;
        }

        &__amounts-fields-container {
          @apply flex gap-5
        }
      }
    }

    &__daily-container {
      @apply flex text-gray-2 justify-between items-center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px; /* 161.538% */
      letter-spacing: -0.32px;

      .daily-items-gap {
        @apply flex gap-8
      }

      .daily-item {
        @apply flex gap-0.5
      }
    }

    &__save-button {
      @apply w-48 h-10
    }

  .mm-panel {
    &__toolbar {

      .switcher-arrow-container {
        @apply flex gap-6 items-center
      }
    }
  }
}

