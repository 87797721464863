.mm-create-buy-sell-bot-task-modal {
  .mm-general-modal__content {
    @apply overflow-hidden flex flex-col;
  }

  form.mm-create-task-form {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: calc(100% - 100px);
      overflow: auto;
      padding: 0 14px;
    }

    .inputs-row {
      @apply flex flex-row items-center gap-4 w-full;

      > div {
        width: 250px;
      }
    }

    .trades-pause-container {
      overflow: hidden;
    }

    .mempool-agruments {
      overflow: hidden;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .mempool-agruments-area {
      width: 100%;
    }
  }

  .mm-new-buy-sell-bot-task-actions {
    @apply flex flex-col gap-1;
    flex-shrink: 0;
  }

  .input-right-caption {
    @apply text-gray-2;
  }
}
