.mm-cex-order-history-table-container {
    @apply w-full overflow-auto relative;
    max-height: 400px;

    .table-loading {
        @apply w-full flex items-center justify-center;
        height: 400px;
    }

    .text-primary {
      font-size: 13px;
      font-weight: 400;
      color: #000;
    }

    .text-secondary {
      font-size: 13px;
      font-weight: 400;
      color: #7F91BB;
    }

    .select-icon-container {
      @apply p-1;
    }

    .status__tooltip {
      @apply text-white font-semibold;
      z-index: 1000;
      letter-spacing: 0.2px;
    }

    .status-indicator {
      @apply rounded-full flex items-center justify-center;
      width: 12px;
      height: 12px;
      flex-shrink: 0;

      .indicator-inner {
        @apply rounded-full;
        width: 8px;
        height: 8px;
      }

      ._active,
      &._active {
        @apply bg-gray-1;
      }

      ._filled,
      &._filled {
        @apply bg-successful-1;
      }

      ._partially_filled,
      &._partially_filled {
        @apply bg-processing-1;
      }

      ._error,
      &._error {
        @apply bg-error-1;
      }

      ._canceled,
      &._canceled {
        @apply bg-blue-500;
      }
    }

    .order-id-text {
      cursor: pointer;

      &:hover {
        @apply underline;
      }
    }

    .direction {
      span {
        @apply font-bold capitalize;
        font-size: 13px;
      }
  
      span.red {
        @apply text-red-1;
      }
  
      span.green {
        @apply text-light-green-1;
      }
    }

    table.mm-cex-order-history-table {
        table-layout: fixed;
    
        thead {
          @apply sticky top-0 bg-white z-10;
        }
    
        th,
        td {
          padding: 5px 10px !important;
          text-overflow: ellipsis;
          overflow: hidden;
        }
    
        tr {
          background-color: white;
        }
    
        tr.tr-colorized {
          background-color: rgba(240, 240, 255, 0.4);
        }
    
        tbody {
          tr {
            .fee-and-select {
              @apply cursor-pointer flex flex-row items-center justify-end flex-wrap;
            }
          }
        }
      }
}