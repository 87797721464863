.mm-statistic-table-container {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 4px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9aabd6;
  }
}

.mm-statistic-table {
  width: auto;
  min-width: 100%;
  table-layout: fixed;

  thead {
    min-width: 100%;

    tr {
      min-width: 100%;
    }

    th {
      width: 16.66%;
      min-width: 140px;
    }

    th {
      @apply text-black font-medium bg-white;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.078px;
    }
  }

  tbody {
    min-width: 100%;

    tr {
      min-width: 100%;
    }

    tr:nth-child(odd) {
      background-color: rgba(240, 240, 255, 0.4);
    }
  }

  tbody,
  thead {
    th {
      padding: 2px;
      text-align: left;
      font-size: 14px;
    }
  }
}
