.mm-pair-transactions-table-container {
  @apply w-full overflow-auto relative;
  max-height: 400px;

  .table-loading {
    @apply w-full flex items-center justify-center;
    height: 400px;
  }

  table.mm-pair-transactions-table {
    table-layout: fixed;

    thead {
      @apply sticky top-0 bg-white z-10;
    }

    th,
    td {
      padding: 5px 10px !important;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    tr {
      background-color: white;
    }

    tr.tr-colorized {
      background-color: rgba(240, 240, 255, 0.4);
    }

    tbody {
      tr._is-extended {
        background: rgba(240, 240, 255, 0.8);
      }

      tr._is-extended-children {
        background: white;
      }

      tr {
        .address-with-link {
          @apply flex flex-row gap-1 items-center;
        }

        .fee-and-select {
          @apply cursor-pointer flex flex-row items-center justify-end gap-2;

          .select-icon-container {
            @apply p-1;
          }
        }

        .direction {
          span.red {
            @apply font-bold capitalize text-red-1;
            font-size: 13px;
          }
      
          span.green {
            @apply font-bold capitalize text-light-green-1;
            font-size: 13px;
          }
        }

        .transaction-hash {
          @apply text-left flex flex-row items-center gap-2;

          .indicator {
            @apply rounded-full flex items-center justify-center;
            width: 18px;
            height: 18px;
            flex-shrink: 0;

            .indicator-inner {
              @apply rounded-full;
              width: 8px;
              height: 8px;
            }
          }

          ._successful,
          &._successful {
            @apply bg-successful-1;
          }

          ._pending,
          &._pending {
            @apply bg-pending-1;
          }

          ._processing,
          &._processing {
            @apply bg-processing-1;
          }

          ._failed,
          &._failed {
            @apply bg-failed-1;
          }

          ._error,
          &._error {
            @apply bg-error-1;
          }

          ._canceled,
          &._canceled {
            @apply bg-error-1;
          }

          &__tooltip {
            @apply text-white font-semibold;
            z-index: 1000;
            letter-spacing: 0.2px;
          }
        }

        .transaction-amount {
          @apply flex flex-row gap-3 items-center;

          &__usd-tooltip {
            @apply font-bold;

            -webkit-box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
            -moz-box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
            box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
          }
        }

        .transfer-amount {
          @apply flex flex-row gap-3 items-center;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__usd-tooltip {
            @apply font-bold;

            -webkit-box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
            -moz-box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
            box-shadow: 0px 0px 7px 3px rgba(216, 217, 232, 1);
          }
        }
      }
    }
  }
}
