.mm-chart-formats {
  @apply flex flex-row items-center gap-2 bg-light-gray-1 p-1 rounded-md;

  div.chart-format-item {
    @apply p-1 rounded-md font-medium cursor-pointer;
    font-size: 13px;
    transition: all 0.3s ease;

    &:hover {
      @apply bg-white;
    }

    &._is-selected {
      @apply bg-main-purple text-white;
    }

    &._is-disabled {
      @apply opacity-60 cursor-not-allowed;
    }
  }
}
