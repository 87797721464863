.mm-panel-error {
  @apply p-3 m-3 flex flex-col items-start justify-start border-2 border-red-1 border-solid rounded-2xl;
  width: calc(100% - 1.5rem);

  &__title {
    @apply flex flex-row gap-2 items-center;

    h3 {
      @apply text-red-1 font-bold text-base;
    }
  }

  &__text {
    @apply text-gray-1 font-normal text-sm whitespace-pre-line;
  }
}
