.mm-wt-bot-form-container {
  @apply w-full flex flex-col gap-5;

  .strategy-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: #5932EA;
  }

  &__loader {
    @apply w-full flex items-center justify-center;
  }
}
