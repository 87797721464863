.mm-date-field-calendar-icon-holder {
    .calendar-icon {
        color: #7F91BB;
    }

    &.error {
       .calendar-icon {
         color: rgb(206, 73, 102) !important;
       }
    }

    &:not(.disabled):hover {
        cursor: pointer;

        .calendar-icon {
            color: #5932EA !important;
        }
    }
}