.mm-table-checkbox-input {
  display: none;
  flex-shrink: 0;
}

.mm-table-checkbox {
  @apply w-4 h-4 flex flex-col items-center justify-center border-gray-2 rounded cursor-pointer ;
  border-width: 2px;
  min-width: 1rem;
}
