.mm-select-dex-pair-modal {
    @apply p-8;

    &__container {
        @apply w-full flex flex-col gap-2;
        max-width: 400px;
    }

    .mm-general-modal {
        &__header, &__content, &__buttons {
        @apply px-0;
        }
    }
}