.mm-field-comment,
  .mm-field-error,
  .mm-field-holder {
    @apply w-full overflow-hidden font-normal text-gray-1;
    cursor: default;
    text-overflow: ellipsis;
    line-height: 16px;
    letter-spacing: -0.08px;
    height: 16px;
    font-size: 13px;

    span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .mm-field-error {
    @apply text-red-1;
  }