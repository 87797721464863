.mm-range-input {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  padding: 5px 0;

  &:focus {
    outline: none; /* Remove border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 4px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -9px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    border-radius: 4px;
    background: #5932ea;
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    height: 18px;
    width: 4px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    background: #5932ea;
  }

  /* All the same stuff for IE */
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 18px;
    width: 4px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    background: #5932ea;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #d9dbe9;
    border-radius: 1.3px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #c4c5d0;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #d9dbe9;
    border-radius: 1.3px;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #d9dbe9;
    border-radius: 2.6px;
  }

  &:focus::-ms-fill-lower {
    background: #c4c5d0;
  }

  &::-ms-fill-upper {
    background: #d9dbe9;
    border-radius: 2.6px;
  }

  &:focus::-ms-fill-upper {
    background: #c4c5d0;
  }
}
