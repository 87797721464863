.mm-wt-presets-modal {
  .mm-general-modal__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .mm-wt-presets-modal__header {
    @apply flex flex-row items-center gap-2;

    span.title {
      @apply font-bold text-main-purple;
      font-size: 21px;
      line-height: 25px;
    }

    button.create-new {
      @apply flex flex-row items-center gap-4 font-semibold text-main-purple;
      padding: 5px 15px;
      font-size: 15px;
      background-color: #f7f5fe;

      &:hover {
        box-shadow: none;
        background-color: #dfdbee;
      }
    }
  }
}

.mm-wt-presets-modal-content {
  @apply w-full h-full flex flex-col overflow-auto;

  .mm-wt-presets-modal__presets {
    min-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    overflow: auto;
    padding: 0 10px;
  }

  .presets-no-info {
    @apply flex items-center justify-center text-lg font-medium mt-4;
  }

  .preset-container {
    @apply w-full flex flex-row items-start justify-between gap-1 overflow-hidden;
  }

  .preset-trash-button {
    @apply p-2 cursor-pointer text-gray-1;
  }

  .mm-wt-presets-modal__action {
    @apply overflow-hidden;
  }

  .preset-item {
    @apply rounded-xl flex flex-col items-center justify-center;
    background: rgba(89, 50, 234, 0.05);
    padding: 12px 16px;
    flex-grow: 1;

    &__inner {
      @apply flex w-full flex-row items-center justify-between cursor-pointer;
    }

    span.name {
      @apply text-main-purple font-bold;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .preset-arrow-button {
    @apply p-1 cursor-pointer;
  }

  .preset-item-extended {
    @apply w-full;
  }
}
