.mm-sidebar__projects__project {
  @apply w-full rounded-2xl px-4 flex flex-col mb-1;
  background: rgba(240, 240, 255, 0.4);
  font-size: 15px;

  &__no-info {
    @apply text-center text-sm block w-full text-gray-1;
  }
  &__trading-pair {
    @apply flex gap-1 items-center
  }
  &__header {
    @apply w-full flex flex-row items-center justify-between gap-2 cursor-pointer py-4;
  }

  &__name {
    @apply font-bold;
    width: calc(100% - 20px);
    font-size: 14px;

    span {
      @apply block w-full whitespace-nowrap text-ellipsis overflow-hidden;
    }
  }

  &__pairs {
    @apply flex flex-col w-full gap-4;

    .mm-common-table-action {
      @apply text-gray-1 cursor-pointer flex flex-row gap-1 p-0;

      &__text {
        @apply text-gray-1 cursor-pointer flex flex-row gap-3 font-normal text-base ;
      }
      icon {
        @apply text-gray-1 p-0 m-0;
      }
    }

    .mm-radio-box {
      cursor: pointer;
      @apply w-auto
    }
  }

  &__add-pair {
    @apply text-gray-1 cursor-pointer flex flex-row gap-3;
    .mm-common-table-action-icon {
      @apply text-gray-1 cursor-pointer flex flex-row gap-3;
    }
  }
  &__trading-pair-icon {
    @apply w-4 h-4;
  }
}
