.wash-algorithm-header {
    @apply flex text-main-purple justify-between items-center mt-5 mb-5 h-5 pl-4;

    &__tab-title {
      @apply text-black ;
      letter-spacing: -0.078px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }
    &__tab-button {
      @apply w-44 h-5
    }
  }
