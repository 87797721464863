@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: SF Pro Text;
    src: url('./assets/fonts/SFProText-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: Droid Sans Mono;
    src: url('./assets/fonts/Droid_Sans_Mono_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }

  * {
    @apply font-body;
    box-sizing: border-box;
  }
}
