.mm-chart-range-controller-wrapper {
  @apply w-auto flex flex-row gap-1 bg-light-gray-1 rounded-md p-1;

  .mm-chart-range-controller__default-ranges {
    @apply flex flex-row items-center gap-1;

    div.range {
      @apply p-1 cursor-pointer rounded-md text-gray-2 text-sm font-medium flex items-center justify-center;
      transition: all 0.3s ease;
      width: 35px;

      &:hover {
        @apply bg-white;
      }

      &._is_selected {
        @apply bg-main-purple text-white;
      }

      &._is_disabled {
        @apply opacity-60 cursor-not-allowed;
      }
    }
  }

  .mm-chart-range-controller__custom-range {
    @apply flex items-center justify-center cursor-pointer rounded-md;
    width: 35px;

    .calendar-icon {
      color: #7F91BB;

      &.selected {
        color: white;
      }
    }

    &:hover {
      @apply bg-white;
    }

    &._is-selected {
      @apply bg-main-purple;
    }

    &._is-disabled {
      @apply opacity-60 cursor-not-allowed;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}
