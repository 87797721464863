.mm-common-table-action {
  @apply flex flex-row items-center gap-1 cursor-pointer py-1 px-1;

  &__text {
    @apply text-main-purple font-bold;
    font-size: 13px;
  }

  &._is_disabled {
    @apply cursor-default;

    .mm-common-table-action__text {
      @apply text-gray-1;
    }
  }
}
