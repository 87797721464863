.mm-filters-container {
    @apply w-full px-1;
    height: 100%;
    overflow-y: auto;

    .delete-filter {
        @apply rounded-md flex flex-row items-center justify-center;
        width: 32px;
        height: 32px;
        background-color: transparent;
        transition: background-color 0.3s ease;
        color: rgb(86, 97, 123);

        svg {
            height: 16px;
            width: 16px;
            transition: fill 0.3s ease;
        }

        &:hover {
            background-color: rgb(252, 239, 240);
            color: rgb( 177, 58, 65 );
        }
    }

    .mm-filter-item_container {
        @apply w-full flex flex-row items-center gap-2 p-2 rounded-md my-1.5;
        background-color: rgb( 247, 248, 249 );

        .select-type-container {
            width: 30%;
        }

        .select-connection-container {
            width: 20%;
        }

        .value-container {
            width: 40%;
        }
    }
}