.mm-withdraw-status-table-container {
    @apply w-full overflow-y-auto relative;
    max-height: 400px;

    .success-status {
        @apply text-successful-1 font-semibold;
    }

    .error-status {
        @apply text-error-1 font-semibold;
    }

    .status {
        @apply flex flex-row items-center gap-2;
    }

    .status-icon-holder {
        @apply text-gray-2;

        svg {
            @apply text-gray-2;
            width: 16px;
            height: 16px;
            cursor: pointer;

            * {
                @apply stroke-gray-2;
            }
        }
    }

    .status__tooltip {
        @apply text-white font-semibold;
        z-index: 1000;
        letter-spacing: 0.2px;
        max-width: 300px;
      }
}