.mm-general-modal-backdrop-holder {
  @apply absolute w-full h-[100vh] top-0 left-0;
}

.mm-general-modal-backdrop-children-holder {
  @apply absolute w-full h-[100vh] top-0 left-0 flex items-center justify-center;
}

.mm-general-modal-backdrop {
  @apply fixed w-full h-full;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.2) 100%);

  backdrop-filter: blur(2px);
  z-index: 100;
}

.mm-general-modal-backdrop__children-wrapper {
  @apply cursor-default fixed flex items-center justify-center;
  max-height: calc(100vh - 20px);
  z-index: 101;
}

.mm-general-modal-backdrop__sidebar-cover {
  @apply fixed top-0 left-0 h-full w-[100vw];
  z-index: 99;
}
