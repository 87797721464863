.mm-pair-wallets-table-loading {
  height: 400px;
  @apply w-full flex items-center justify-center;
}

.pair-page-wallets-table-container {
  @apply overflow-y-auto mb-5;
  max-height: 400px;

  thead {
    position: -webkit-sticky !important;
  }
}

.ww-status-button {
  @apply flex flex-row items-center px-1 rounded-xl cursor-pointer;
  gap: 2px;

  &._pending {
    background-color: #ce4966;
    -webkit-animation-name: color-transition;
    animation-name: color-transition;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

    @keyframes color-transition {
      0% {
        background-color: #ce4966;
      }
      100% {
        background-color: #ff7c99;
      }
    }
  }

  &._success {
    background-color: white;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .pending-count {
    @apply text-white;
    font-size: 12px;
    font-weight: 500;
    padding-right: 2px;
  }
}
