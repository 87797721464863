.mm-header__header-project {
  @apply w-full flex flex-col gap-2 pt-5;

  &__info {
    @apply w-full flex flex-row items-center gap-2;

    h2.project-name {
      @apply text-2xl text-black font-bold;
    }

    div.pair-settings {
      @apply cursor-pointer;
    }
  }

  &__notes {
    width: 100%;
    span {
      @apply w-full text-gray-1 block overflow-hidden text-ellipsis;
    }
  }
}
