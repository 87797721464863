table.mm-wash-algorithm-table {
  @apply w-full;

  tbody tr {
    background-color: transparent !important;
    cursor: pointer;
  }

  tbody tr.tr-separator {
    height: 10px;
  }

  tbody td {
    & > div {
      @apply whitespace-nowrap;
    }

    @apply p-4 font-normal text-black h-full;
    background-color: #f9f9ff;
    border-bottom: 10px solid transparent;
    font-size: 13px;
  }

  tbody td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  tbody td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  tr._selected {
    td {
      border-bottom: 0px;
    }

    td:first-child {
      border-bottom-left-radius: 0px;
    }
    td:last-child {
      border-bottom-right-radius: 0px;
    }
  }

  tr.task-finished {
    opacity: 0.5;
  }

  .no-active-records {
    @apply w-full flex items-center justify-center;

    span {
      @apply font-medium text-main-purple text-base;
    }
  }

  .time-cell {
    @apply flex items-center gap-1 whitespace-nowrap;

    div.round {
      @apply rounded-full;
      width: 8px;
      height: 8px;

      &.round-purple {
        @apply bg-main-purple;
      }
    }

    span {
      @apply font-normal text-black;
      font-size: 13px;
    }
  }

  .direction-cell {
    @apply flex items-center pl-3;

    span {
      @apply font-bold capitalize;
      font-size: 13px;
    }

    span.red {
      @apply text-red-1;
    }

    span.green {
      @apply text-light-green-1;
    }
  }

  .open-row-cell {
    @apply cursor-pointer flex items-center justify-end h-full ;
  }


  .switcher-cell {
    @apply cursor-pointer flex justify-end items-center gap-8;
  }

  tr.mm-wash-algorithm-opened-task {
    overflow: hidden;

    td {
      border-radius: 0px;
      padding-top: 0px;
    }

    td:first-child {
      border-bottom-left-radius: 20px;
    }
    td:last-child {
      border-bottom-right-radius: 20px;
    }
  }
}

.mm-wash-algorithm-table-loader {
  @apply w-full flex items-center justify-center p-2;
}
