.mm-json-editor-container {
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mm-json-editor-textarea {
    padding: 10px;
    height: calc(100% - 40px);
    width: calc(100% - 2px);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &:focus,
    &:focus-within {
      @apply outline-gray-400;
    }
  }

  .tools-headers {
    @apply bg-gray-400;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    height: 40px;
  }

  .prettier-button {
    @apply p-1 bg-white text-sm rounded-md;
  }
}
