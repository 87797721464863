table.mm-dex-wt-organic-volumes-table {
    @apply w-full;
  
    tbody tr {
      background-color: transparent !important;
      cursor: pointer;
    }
  
    tbody tr.tr-separator {
      height: 10px;
    }
  
    tbody td {
      @apply p-4 font-normal text-black h-full;
      background-color: #f9f9ff;
      border-bottom: 10px solid transparent;
      font-size: 13px;
    }
  
    tbody td:first-child {
      border-left-style: solid;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    tbody td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }

    tr._selected {
      td {
        border-bottom: 0px;
      }
  
      td:first-child {
        border-bottom-left-radius: 0px;
      }
      td:last-child {
        border-bottom-right-radius: 0px;
      }
    }
  
    .no-active-records {
      @apply w-full flex items-center justify-center;
  
      span {
        @apply font-medium text-gray-1 text-base;
      }
    }  

    .switcher-cell {
        @apply cursor-pointer flex justify-end items-center gap-8;
    }

    tr.opened-task {
        overflow: hidden;
    
        td {
          border-radius: 0px;
          padding-top: 0px;
        }
    
        td:first-child {
          border-bottom-left-radius: 20px;
        }
        td:last-child {
          border-bottom-right-radius: 20px;
        }
      }
}