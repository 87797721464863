.mm-manual-txs-modal-container {
  @apply flex flex-col items-center gap-3;

  .no-transactions {
    @apply w-full flex items-center justify-center p-3 bg-light-gray-1 rounded-xl;

    span {
      @apply text-sm text-gray-2;
      text-align: center;
    }
  }

  .transactions-list {
    @apply w-full flex flex-col items-start gap-2 overflow-y-scroll px-2;
    max-height: 300px;
  }

  .transaction-item {
    @apply w-full flex flex-row items-center justify-between gap-4;

    &__left {
      @apply flex flex-row items-center gap-2;

      .index {
        @apply text-gray-2 text-xs;
      }

      .tx-address {
        @apply text-sm;
      }
    }

    button.delete-transaction-item {
      @apply p-1;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .button-paste-txs {
    @apply px-4 py-2 rounded-xl bg-main-purple text-white font-semibold mb-3;
    transition: all 0.3s ease;

    &:hover {
      background-color: #441fc9;
    }
  }
}
