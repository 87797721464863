.mm-table-address-chip {
  @apply flex items-center;
  gap: 2px;

  &__address {
    @apply cursor-pointer font-monospace font-medium;
    letter-spacing: -0.7px;
    font-size: 13px;

    &:hover {
      @apply underline;
    }
  }

  &__scan-link {
    @apply p-1;

    svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
}
