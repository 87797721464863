.mm-buy-sell-bot-table-container {
  @apply m-2 w-full;

  .table-container {
    @apply overflow-auto;
    max-height: 500px;
    width: calc(100% - 1rem);
    padding: 0 0.5rem;
  }
}
