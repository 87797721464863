.mm-wt-dex-bot-regular-volumes-toolbar {
    @apply w-full flex flex-row items-center justify-between gap-4 px-5;

    h4 {
        color: #5932EA;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.5px;
    }

    button {
        width: auto;
        font-size: 13px;
        padding: 8px;

        &:hover {
            background-color: transparent;
        }
    }
}