.mm-filter-select-field__dropdown {
    @apply rounded-md;
    z-index: 102;

    background-color: white;
    outline: none;
    padding: 8px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .25);

    .dropdown__item {
        @apply rounded-md cursor-pointer;
        background-color: white;

        padding: 8px;
        color: #2a2e34;
        font-size: 13px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgb( 240, 241, 243 );
        }
    }
}

.mm-filter-field-container {
    @apply w-full flex flex-row py-2 px-3 items-center justify-between gap-2 cursor-pointer outline-none;
    @apply rounded-md;
    background-color: white;
    border: 1px solid rgb( 214, 217, 222 );

    transition: border 0.3s ease;

    &:hover {
        border: 1px solid rgb(173, 179, 189);
    }
}

.mm-filter-field-placeholder {
    color: rgb( 135, 144, 158 );
    font-size: 14px;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mm-filter-field-value {
    color: rgb( 42, 46, 52 );
    font-size: 14px;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mm-filter-select-field {
    @apply w-full;
}

.mm-filter-input-field {
    @apply w-full;
}