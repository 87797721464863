.mm-project-statistic-panel {
  @apply w-full p-5 flex flex-col items-start gap-8;

  .range-container {
    @apply w-full flex flex-row items-center justify-end gap-4;

    .range-label {
      @apply text-gray-2;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}
