.mm-static-table {
    @apply w-full;
  
    * {
      font-size: 13px;
    }
  
    td,
    th {
      @apply py-2 px-4;
    }
  
    thead {
      * {
        @apply text-gray-1 font-normal;
        font-size: 13px;
        line-height: calc(19 / 13);
        letter-spacing: -0.078px;
      }
    }
  
    tbody {
      * {
        @apply text-black;
      }
  
      tr:nth-child(odd) {
        background-color: rgba(240, 240, 255, 0.4);
      }
    }
  }