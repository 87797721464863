.mm-trading-pair-modal {
  @apply p-8;
  &__trading_pair_container {
    @apply flex flex-col gap-7;
  }

  .mm-general-modal {
    &__header, &__content, &__buttons {
      @apply px-0;
    }
  }
}