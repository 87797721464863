.mm-panel-task-wrapper {
  @apply pt-2 pb-4 px-2;
  border-bottom: 1px solid rgba(127, 145, 187, 0.2);

  & > div {
    background-color: #F9F9FF;
  }

  & .mm-input-field__inner__input {
    @apply text-base leading-5;
  }

  & .mm-panel__toolbar__title {
    @apply flex-col items-start gap-1;

    & > .mm-panel__toolbar__title__inner {
      font-size: 16px;
      font-weight: 600;
      line-height: calc(21 / 16);
      letter-spacing: -0.32px;
    }

    & > .mm-panel__toolbar__subTitle__inner {
      font-size: 13px;
      line-height: calc(19 / 13);
      letter-spacing: -0.078px;
      color: rgba(89, 50, 234, 0.7);
    }
  }
}

.mm-main-fee-balance-task {
  @apply py-6 px-4 w-full;

  & > .mm-task-fields {
    @apply flex items-start gap-6;
  }

  .mm-first-row-fields {
    @apply flex gap-6 items-start;
  }

  .mm-second-row-fields {
    @apply flex gap-6;
  }

  .mm-fee-confirm-section {
    @apply flex justify-between items-center pt-4;

    & .fee-confirm-section-wrapper {
      @apply flex gap-5;
      font-size: 13px;
      font-weight: 600;
      line-height: calc(21 / 13);
      letter-spacing: -0.32px;
      width: 77%;
      color: #7F91BB;
    }
  }

  .wallets-count-text {
    @apply text-main-purple whitespace-nowrap cursor-pointer;
    letter-spacing: -0.32px;
    font-size: 16px;
    line-height: calc(21 / 16);
    font-weight: 600;
  }
}

.button-save-container {
  @apply flex justify-end;
  width: 33%;

  &__save-task-button {
    @apply text-main-purple;
    padding: 13px 20px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    width: 238px;
    height: 42px;
  }
}
