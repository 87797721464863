.mm-range-calendar-modal {
  @apply w-full flex flex-col items-center gap-5 p-1 outline-none;

  &__buttons {
    @apply w-full flex flex-row gap-2 items-center;
  }

  .react-calendar {
    @apply border-none;
  }
}
