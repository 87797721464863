.mm-calendar-modal {
  @apply w-full flex flex-col items-center gap-5 p-1 outline-none;

  .react-calendar {
    @apply border-none;
  }

  .react-time-picker__wrapper {
    border: none;
    @apply bg-light-gray-1 rounded-3xl px-4 py-1 font-bold text-gray-1;
  }
}
