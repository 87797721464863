.mm-header {
  @apply w-full flex flex-row items-center justify-between py-2 px-5 gap-5;

  &__left {
    width: calc(100% - 350px);
  }

  &__right {
    @apply flex flex-row gap-3 flex-shrink-0 justify-end;
    width: 300px;

    div.mm-connect-wallet-button {
      width: 100%;
    }

    button {
      width: 100%;
      white-space: nowrap;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
