.mm-statistic-table-cell {
  @apply flex flex-row items-center;
  padding: 6px;
  gap: 2px;


  .value {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #000000;
    font-weight: 400;
  }

  .thin {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
  }
}
