.mm-wt-bot-additional-reserves {
    @apply flex flex-col gap-1;

    .switcher-container {
        @apply flex flex-row items-center gap-2;
    }

    .switcher-caption {
        @apply text-sm;
    }

    .inputs {
        @apply flex flex-col items-center;
    }
}