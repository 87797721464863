table.mm-dex-wt-regular-volumes-table {
    @apply w-full;
  
    tbody tr {
      background-color: transparent !important;
      cursor: pointer;
    }
  
    tbody tr.tr-separator {
      height: 10px;
    }
  
    tbody td {
      @apply p-4 font-normal text-black h-full;
      background-color: #f9f9ff;
      border-bottom: 10px solid transparent;
      font-size: 13px;
    }
  
    tbody td:first-child {
      border-left-style: solid;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    tbody td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }
  
    .no-active-records {
      @apply w-full flex items-center justify-center;
  
      span {
        @apply font-medium text-gray-1 text-base;
      }
    }  

    .start-condition-cell {
        @apply flex flex-row gap-2 items-center;

        .circle {
            @apply rounded-full bg-main-purple flex-shrink-0;
            width: 8px;
            height: 8px;
        }
    }

    .actions-cell {
        @apply flex flex-row gap-1 items-center justify-end;

        button.action-button {
            @apply outline-none border-none bg-transparent rounded-md p-1;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #e4e4e4;
            }

            * {
                color: #7492D9 !important;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}