.mm-base-multi-select-field {
    @apply w-full flex flex-col gap-1 pt-5;

    &__container {
        @apply w-full;
        outline: 0;
        cursor: pointer;

        * {
            cursor: pointer;
        }

        &._disabled {
            cursor: default;
    
            * {
                cursor: default;
            }
        }
    }

    &__line {
        @apply w-full;
      }
    
      &__inner {
        @apply w-full flex gap-2 items-center;
    
        &__input-holder {
          @apply relative w-full;
        }
    
        &__input {
          @apply w-full outline-none py-1 font-normal text-base text-black-1 bg-transparent;
          letter-spacing: -0.32px;
        }
    
        &__input:-webkit-autofill,
        &__input:-webkit-autofill:hover,
        &__input:-webkit-autofill:focus,
        &__input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
    
        &__input::placeholder {
          @apply text-gray-1 font-normal text-sm overflow-hidden text-ellipsis w-full block;
          letter-spacing: -0.32px;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
    
        &__input._focused::placeholder {
          opacity: 1;
        }
    
        &__input:disabled {
          cursor: not-allowed;
        }
    
        &__label {
          position: absolute;
          white-space: nowrap;
          display: flex;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          width: 100%;
          display: block;
    
          &._disabled {
            cursor: not-allowed;
          }
        }
      }

    .arrow-angle-down {
        transition: transform 0.3s ease;

        &:hover {
            path {
                @apply fill-light-purple-1;
            }
        }

        &._opened {
            transform: rotate(-180deg);

            path {
                @apply fill-light-purple-1;
            }
        }
    }
}
  

.mm-base-multi-select-field__dropdown {
    min-width: 100px;
    border-radius: 8px;
    outline: 0;
    overflow-y: auto;
    background: #eee;
    z-index: 102;

    &__item {
        @apply flex flex-row items-center gap-2;

        outline: none;
        padding: 10px;
        cursor: default;
    }

    .dropdown__not-found {
      @apply w-full flex items-center justify-center text-sm text-black-1 p-3;
    }
}