.mm-select-network-field-item {
    @apply p-2 text-sm flex flex-row items-center gap-2;

    &._selected {
        @apply text-main-purple;
    }

    .network-image {
        width: 20px;
        height: 20px;
    }
}