.accounts-panel-wallets-table-toolbar__actions {
    @apply flex flex-row items-center gap-5;
  }
  
  .accounts-table-container {
    @apply w-full overflow-scroll;
    max-height: 400px;
  
    thead {
      position: -webkit-sticky !important;
    }
  
    &__loading {
      @apply w-full p-1 text-center;
    }
  }
  