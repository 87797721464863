.mm-connect-dex-wallets-bb-container {
    width: 650px;

    .table-loading {
        height: 350px;
        @apply w-full flex items-center justify-center;
    }
    
    .table-container {
        @apply overflow-y-auto mb-5 w-full;
        max-height: 350px;
    
        table {
            width: 100%;
        }
      
        thead {
          position: -webkit-sticky !important;
        }
    }
} 