.mm-pair-table-connected-bots {
  @apply flex flex-row items-center justify-end gap-1;

  .bot-circle {
    @apply flex items-center justify-center font-bold cursor-pointer bg-white text-gray-2 rounded-full border border-gray-2;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    width: 25px;
    height: 25px;

    &._connected {
      @apply bg-main-purple text-white border-main-purple;
    }
  }

  .mm-common-spinner {
    width: 25px !important;
    height: 25px !important;
  }
}
