.mm-create-add-holder-task-modal {
  form.mm-create-task-form {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: calc(100% - 100px);
      overflow: auto;
      padding: 0 10px;
    }

    .inputs-row {
      @apply flex flex-row items-center gap-4 w-full;

      > div {
        width: 250px;
      }
    }
  }

  .mm-new-buy-sell-bot-task-actions {
    @apply flex flex-col gap-1;
    flex-shrink: 0;
  }
  .add-holder-container {
    @apply text-gray-1 text-sm;
    &__indicator {
      @apply flex justify-between mt-5 mb-2.5;
    }
  }

  .wallets-count-text {
    @apply text-main-purple whitespace-nowrap cursor-pointer;
    letter-spacing: -0.078px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;

    //&.disabled {
    //  @apply text-gray-1 cursor-default;
    //}
  }
 .mm-input-field-purple-text {
   input {
     @apply text-main-purple whitespace-nowrap cursor-pointer;
     letter-spacing: -0.078px;
     font-size: 14px;
     line-height: 18px;
     font-weight: 600;
     }
  }

  //.mm-input-field-gray-text {
  //  input {
  //    @apply text-gray-1 cursor-default;
  //  }
  //}
}
