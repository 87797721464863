.mm-create-withdraw-whitelist-modal-content {
  max-width: 600px;
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .modal-content-form {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;

    .addresses-info {
      @apply text-sm text-gray-1;
    }

    .addresses-grid {
      @apply flex flex-col w-full gap-3 rounded-xl bg-light-gray-1 items-start overflow-y-auto;
      padding: 12px 20px;
      flex: 1 1 auto;
    }

    .address-cell {
      @apply w-full text-center rounded-xl bg-main-purple text-white font-bold;
      font-size: 15px;
      padding: 4px 8px;
    }
  }

  .modal-content-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
