.mm-textarea-container {
  @apply w-full flex flex-col justify-center items-start;

  &__content {
    @apply relative w-full flex items-center justify-between bg-light-gray-1;
    border-radius: 20px;

    &__textarea {
      @apply px-5 py-4 pr-2 w-full text-base font-bold bg-transparent outline-none text-main-purple resize-none;
      border-radius: 20px;

      &::placeholder {
        @apply font-bold text-base text-gray-1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
