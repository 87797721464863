.mm-wt-bot-scroll-controller {
  @apply w-full flex flex-col gap-4;

  .controller-head {
    @apply w-full flex flex-row items-center justify-between;
  }

  span {
    @apply font-bold text-main-purple;
    font-size: 14px;
    line-height: 18px;
  }

  .total-frequency-range {
    rotate: 180deg;

    &::-webkit-slider-thumb {
      margin-top: -5px;
    }
  }
}
