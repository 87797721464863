.mm-button {
  @apply w-full cursor-pointer text-sm font-bold outline-none flex flex-row items-center justify-center;
  border-radius: 44px;
  transition: all 0.3s ease;

  &-big {
    @apply p-4 gap-3;
  }

  &-small {
    @apply p-3 text-xs gap-2;
  }

  &-primary {
    @apply text-white font-bold bg-main-purple border-main-purple border-2 border-solid;

    &:hover,
    &:active {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.23);
    }
  }

  &-secondary {
    @apply font-bold text-main-purple border-main-purple border-2 border-solid;
    background: transparent;

    &:hover {
      background: rgba(92, 92, 92, 0.04);
    }
  }

  &-error-bordered {
    @apply font-bold text-error-1 border-error-1 border-2 border-solid;
    background: transparent;

    &:hover {
      background: rgba(92, 92, 92, 0.04);
    }
  }

  &-transparent {
    @apply font-bold text-main-purple border-2 border-solid border-transparent;
    background: transparent;

    &:hover {
      background: rgba(92, 92, 92, 0.04);
    }
  }

  &-error {
    @apply font-medium bg-red-1 text-white border-2 border-solid border-transparent;

    &:hover,
    &:active {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.23);
    }
  }

  &-error-secondary {
    @apply font-bold text-red-1 border-2 border-solid border-transparent;
    background: transparent;
  }

  &-disabled {
    @apply text-light-gray-2 bg-dark-purple-1 cursor-not-allowed;
    box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);
    transition: box-shadow 0.3s ease;
  }
}

// button with loading
.mm-button-with-loader {
  @apply cursor-default;

  &__loader-wrapper {
    @apply w-full flex items-center justify-center;
  }

  &__loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    animation: spinner-bulqg1 0.8s infinite linear alternate, spinner-oaa3wk 1.6s infinite linear;
  }

  @keyframes spinner-bulqg1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
      transform: scaleY(1) rotate(0deg);
    }

    49.99% {
      transform: scaleY(1) rotate(135deg);
    }

    50% {
      transform: scaleY(-1) rotate(0deg);
    }

    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
}
