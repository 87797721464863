.statistic-calendar-selector {
  @apply flex flex-row justify-end items-center cursor-pointer gap-2 h-10;

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }

  &__caption {
    @apply text-main-purple font-bold;
    font-size: 14px;
  }
}
