.mm-table-tokens {
  @apply flex flex-row gap-2 items-center;

  .caption {
    @apply text-black;
    font-size: 13px;
    white-space: nowrap;
  }

}
