.mm-wt-bot-panel__reseed-button {
  @apply bg-light-gray-1 text-main-purple border-none;

  width: 100px;
  padding: 12px;

  &:hover {
    box-shadow: none;
  }
}
