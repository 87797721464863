.mm-dex-wt-organic-volume-row-extended-info {
    @apply grid gap-4 overflow-hidden;

  div.line-separator {
    @apply bg-gray-2 w-full opacity-20;
    height: 0.5px;
  }

  .sections {
    @apply flex flex-col gap-2.5 w-full;
  }

  .section-title {
    @apply text-main-purple font-semibold;
    font-size: 16px;
    line-height: calc(21 / 16);
    letter-spacing: -0.32px;
  }

  .section {
    @apply grid gap-x-6 gap-y-2.5 align-top;

    grid-template-columns: auto 1fr;

    span {
      font-size: 13px;
      line-height: 18px;
    }

    span._locked {
      @apply text-gray-2;
    }
  }

  .edit-task-button {
    @apply text-main-purple;
    padding: 13px 20px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    width: 180px;
  }

  .delete-task-button {
    @apply text-error-1;
    padding: 13px 20px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    width: 180px;
  }

  .extended-row__footer {
    @apply flex flex-row justify-end items-center gap-10 font-bold;

    span {
      font-size: 13px;
    }
  }
}