.mm-statistic-table-header {
  padding: 2px 6px;

  text-align: left;
  color: #000000;
  letter-spacing: -0.078px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
