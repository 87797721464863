.mm-filter-popup-trigger-button {
    @apply flex flex-row items-center gap-1 cursor-pointer;
    color: rgb(127 145 187);

    &.is_setted {
       color: #5932EA !important;
    }

    svg {
        width: 18px;
        height: 18px;
    }

    span {
        @apply text-sm font-semibold;
    }
}

.mm-filters-popup-container {
    @apply rounded-lg bg-white flex flex-col items-center gap-4;
    border: 1px rgb( 232, 234, 237) solid;
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .08);
    padding: 16px;
    max-width: 820px;
    width: 100%;

    .filters-header {
        @apply flex flex-row items-center gap-4 justify-between w-full;
    }

    .filters-title {
        @apply text-base font-semibold;
        color: rgb(86, 97, 123);
    }

    .filter-header-right {
        @apply flex flex-row items-center gap-2;
    }

    .clear-filters {
        @apply border-none text-xs px-3 py-2 rounded-md;
        color: rgb(86, 97, 123);
        background-color: white;
    }

    .filters-footer {
        @apply w-full flex flex-row items-center justify-between gap-1;
    }

    .filters-add-filter {
        @apply flex flex-row items-center text-sm px-2 py-1 rounded-md;
        border: 1px solid rgb(214, 217, 222);
        background-color: white;
    }

    .filters-start-filter {
        @apply flex flex-row items-center text-sm px-6 py-2 rounded-md;
        color: white;
        background-color: #5932EA;
    }
}