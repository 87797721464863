.mm-pair-pair-chart-tooltip {
    @apply absolute top-1 left-1 flex flex-col gap-1 items-start;
    font-size: 13px;
    z-index: 100;

    .tooltip-header {
        @apply flex flex-row items-center gap-4 py-1 px-2 rounded-sm;
        background-color: #eeeeeec6;
    }

    .tooltip-title {
        @apply text-sm font-medium text-slate-900;
    }

    .pair-values {
        @apply flex flex-row items-center gap-2 rounded-sm;
        padding: 2px 4px;
        background: #f0f0f0cb;
    }

    div.value {
        @apply font-monospace;
        display: inline;
    }

    div.volume-value {
        @apply font-monospace flex;
        display: inline;
        font-size: 12px;
    }

    .round {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
    }

    .caption {
        @apply text-slate-800 font-monospace;
    }

    .amount {
        @apply font-monospace font-medium;
        margin-left: 1px;
        letter-spacing: -0.4px;

        &.green {
            color: rgb(14, 203, 129);
        }

        &.red {
            @apply text-red-700;
        }
    }
}