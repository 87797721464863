.mm-balance-bot-row-extended-info {
  @apply grid gap-4 overflow-hidden;

  div.line-separator {
    @apply bg-gray-2 w-full opacity-20;
    height: 0.5px;
  }

  .section-value {
    font-size: 13px;
    line-height: calc(19 / 13);

    & span:first-child {
      letter-spacing: -0.078px;
    }

    & span:last-child {
      font-weight: 600;
      letter-spacing: -0.32px;
    }
  }

 .rowInfo-container {
  @apply grid  grid-cols-2 gap-24 overflow-auto py-2;

   & .wallets-section-value {
     font-size: 13px;
     line-height: calc(19 / 13);

     & span:first-child {
       letter-spacing: -0.078px;
     }

     &:not(._not_active) {
      & span:last-child {
        @apply text-main-purple underline;
        font-weight: 600;
        letter-spacing: -0.32px;
      }
     }
   }
 }
  .rowInfo-bottom-container {
    @apply flex justify-between items-center;

    & .bottom-sections {
      @apply flex gap-8;
    }

    & .bottom-sections-content {
      @apply gap-1;
    }
  }
  .sections {
    @apply grid grid-cols-1 gap-x-6 gap-y-2;

    //grid-template-rows: 1fr 1fr;
  }

  .section-title {
    @apply text-main-purple font-bold;
  }

  .section {
    @apply grid gap-4 align-top;

    grid-template-columns: repeat(3, minmax(min-content, 1fr));

    span {
      font-size: 13px;
      line-height: 18px;
      height: 36px;
    }

    span._locked {
      @apply text-gray-2;
    }
  }



  .extended-row__footer {
    @apply flex flex-row justify-end items-center gap-5 font-bold;
    .edit-task-button {
      @apply text-main-purple w-48 /*py-3.5 px-5 */;
      padding: 13px 20px !important;
      font-size: 13px !important;
      line-height: 16px !important;
      //width: 190px;
    }
    .delete-task-button {
      @apply text-red-1 border-red-1 w-48;
      padding: 13px 20px !important;
      font-size: 13px !important;
      line-height: 16px !important;
      //width: 180px;
    }
    span {
      font-size: 13px;
    }
  }
}
