.mm-statistic-table-checkbox-header {
  @apply flex flex-row items-center;
  gap: 6px;

  > span {
    line-height: 18px;
    font-size: 13px;
  }

  .checkbox-area {
    @apply flex flex-row items-center gap-1 cursor-pointer;

    .statistic-checkbox {
      width: 12px;
      height: 12px;
    }

    span {
      @apply text-gray-2 font-normal;
      font-size: 10px;
      line-height: 12px;
    }
  }
}
