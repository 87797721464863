.mm-header__header-pair {
  @apply w-full flex flex-col gap-2 pt-5;

  &__info {
    @apply w-full flex flex-row items-center gap-2 relative;

    .pair-name {
      @apply flex items-center gap-1;

      * {
        @apply text-2xl text-black font-bold;
      }
    }

    div.pair-settings {
      @apply cursor-pointer;
    }

    .project-name {
      @apply text-main-purple font-bold text-sm cursor-pointer;
      transition: all 0.3s ease;

      &:hover {
        text-decoration: underline;
      }
    }
    .header-trading-pair-logo-header {
      @apply flex flex-row items-center gap-1 rounded-2xl bg-white py-1 px-3 w-fit;
      
      img {
        width: auto;
        height: 16px;
      }

      span {
        @apply text-xs font-extrabold;
      }
    }
  }

  &__bottom {
    @apply w-full flex items-center gap-2;

    a.scan-link {
      cursor: pointer;
      flex-shrink: 0;
      img {
        width: 25px;
        height: 25px;
      }
    }

    a.trade-pair-link {
      cursor: pointer;
      flex-shrink: 0;
      img {
        width: 25px;
        height: 25px;
      }
    }

    span {
      @apply w-full text-gray-1 block overflow-hidden text-ellipsis;
    }

    .header-trading-pair-logo-bottom {
      width: 40px;
      height: 40px;
    }
  }
}
