.mm-sidebar {
  @apply p-5 bg-white flex flex-col items-center w-64 flex-shrink-0;
  height: 100vh;
  border-radius: 0px 30px 30px 0px;

  &__header {
    @apply py-3 flex w-full flex-row items-center gap-4 justify-center;

    img {
      width: 50px;
    }

    > h2 {
      @apply text-xl whitespace-nowrap font-extrabold;
    }
  }

  &__separator {
    @apply w-full h-[1px] bg-gray-1 bg-opacity-20;
  }

  &__content {
    @apply h-full w-full;
    padding-top: 16px;
    height: calc(100% - 16px - 1.25rem);
  }

  &__menu {
    @apply h-full w-full flex flex-col flex-nowrap;

    &__item {
      @apply w-full flex flex-row gap-2 cursor-pointer py-2 my-3;
      * {
        transition: all 0.3s ease;
      }

      &__left {
        @apply flex flex-row items-center gap-2;
      }

      &__text {
        @apply font-semibold text-gray-1 whitespace-nowrap;
        font-size: 14px;
      }

      &._active &__text {
        @apply text-main-purple;
      }
    }
  }
}
