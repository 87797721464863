.mm-wt-bot-form-chart-info {
  @apply w-full grid gap-x-3 gap-y-3 p-5;
  border-radius: 20px;
  background: rgba(240, 240, 255, 0.4);
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;

  .chart-info-section {
    @apply w-full flex items-start justify-between gap-2;

    span {
      font-size: 13px;
    }

    span.caption {
      white-space: nowrap;
    }
  }

  &._loading {
    @apply flex items-center justify-center gap-0;
    height: 90px;
  }

  &._error {
    @apply flex items-center justify-center gap-0;
    height: 90px;
    background-color: rgba(174, 42, 42, 0.248);

    .error-title {
      @apply text-red-1 text-base font-medium;
    }
  }
}
