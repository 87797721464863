.mm-table-th-connected-bots {
  @apply flex flex-row items-center gap-1 justify-end relative;

  > button {
    @apply p-1 cursor-pointer;
  }

  span {
    @apply font-normal;
    font-size: 14px;
    line-height: 17px;
  }

  &._active {
    span {
      @apply text-main-purple font-semibold;
      cursor: pointer;
    }
  }
}

.connected-bots-menu {
  @apply rounded-2xl border-2 border-main-purple z-10 bg-white outline-none;
  width: 250px;
  padding: 16px;

  .connected-bots-menu__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .bot-switcher-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
  }

  .bot-loading {
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mm-common-spinner {
      width: 24px;
      height: 24px;
    }
  }
}
