.mm-statistic-table-total-cell {
  @apply flex flex-row items-center;
  padding: 6px;
  gap: 8px;

  .value {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #000000;
    font-weight: 600;
  }

  .subvalue {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #000000;
    font-weight: 500;
  }
}
