.mm-switcher {
  input {
    display: none;
  }

  &__label {
    @apply block cursor-pointer relative rounded-3xl bg-light-gray-2 items-center text-sm;
    width: 41px;
    height: 24px;

    .toggler {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: block;
      position: absolute;
      background-color: white;
      top: 2px;
      bottom: 2px;
    }
  }

  input:checked {
    & + .mm-switcher__label {
      @apply bg-main-purple;
    }
  }
}
