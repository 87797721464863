.mm-pair-balance-chart-tooltip {
    @apply absolute flex flex-col gap-1 items-start top-1 left-1;
    font-size: 12px;
    z-index: 100;

    .tooltip-header {
        @apply flex flex-row items-center gap-4 py-1 px-2 rounded-sm;
        background-color: #eeeeeec6;
    }

    .tooltip-balance {
        @apply flex flex-row items-center gap-2 rounded-sm;
        background-color: #eeeeeec6;
        padding: 2px 4px;
        font-size: 11px;
    }

    .tooltip-title {
        @apply text-sm font-medium text-slate-900;
    }

    .round {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
    }
    
    .total-balance-value {
        @apply flex flex-row items-center gap-1
    }

    .caption {
        @apply text-slate-800;
        font-size: 12px;
    }

    .amount {
        @apply font-monospace font-medium;
        margin-left: 1px;
        letter-spacing: -0.4px;

        &.green {
            color: rgb(14, 203, 129);
        }

        &.red {
            @apply text-red-700;
        }
    }
}