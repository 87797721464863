.statistic-buy-sell-cell {
  @apply flex flex-row items-center;
  padding: 6px;
  gap: 8px;

  .caption {
    width: 60px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    &.buy {
      @apply text-successful-1;
    }

    &.sell {
      @apply text-red-1;
    }
  }

  .title {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    letter-spacing: -0.078px;
  }
}
