.mm-afr-bot-panel__form {
  @apply w-full flex flex-row flex-wrap items-center gap-7;

  .protection-contract {
    width: 300px;
  }

  .depth-blocks {
    width: 200px;
  }

  .mm-afr-bot-panel__submit-button {
    width: 200px;
    padding: 14px;
  }
}
