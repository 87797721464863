.mm-input-field {
  @apply w-full flex flex-col gap-1 pt-5;

  &__container {
    @apply w-full;
  }

  &__line {
    @apply w-full;
  }

  &__inner {
    @apply w-full flex gap-2 items-center;

    &__input-holder {
      @apply relative w-full;
    }

    &__input {
      @apply w-full outline-none py-1 font-normal text-base text-black-1 bg-transparent;
      letter-spacing: -0.32px;
    }

    &__input:-webkit-autofill,
    &__input:-webkit-autofill:hover,
    &__input:-webkit-autofill:focus,
    &__input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }

    &__input::placeholder {
      @apply text-gray-1 font-normal text-base overflow-hidden text-ellipsis w-full block;
      letter-spacing: -0.32px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &__input._no-label::placeholder {
      opacity: 1;
    }

    &__input._focused::placeholder {
      opacity: 1;
    }

    &__input:disabled {
      cursor: not-allowed;
    }

    &__label {
      position: absolute;
      white-space: nowrap;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      width: 100%;
      display: block;

      &._disabled {
        cursor: not-allowed;
      }
    }
  }

  &__password-eye {
    @apply p-1 cursor-pointer flex-shrink-0;
  }
}
