.mm-buy-sell-bot-row-extended-info {
  @apply grid gap-4 overflow-hidden;

  div.line-separator {
    @apply bg-gray-2 w-full opacity-20;
    height: 0.5px;
  }

  .sections {
    @apply grid grid-cols-2 gap-x-6 gap-y-2;

    grid-template-rows: max-content 1fr;
  }

  .section-title {
    @apply text-main-purple font-semibold;
    font-size: 16px;
    line-height: calc(21 / 16);
    letter-spacing: -0.32px;
  }

  .section {
    @apply grid gap-4 align-top;

    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    span {
      font-size: 13px;
      line-height: 18px;
      height: 36px;
    }

    span._locked {
      @apply text-gray-2;
    }
  }

  .edit-task-button {
    @apply text-main-purple;
    padding: 13px 20px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    width: 180px;
  }

  .delete-task-button {
    @apply text-error-1;
    padding: 13px 20px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    width: 180px;
  }

  .extended-row__footer {
    @apply flex flex-row justify-between items-center gap-10 font-bold;

    span {
      font-size: 13px;
    }
  }
}
