.statistic-tab-container {
  @apply w-full flex flex-col items-center;

  .toolbar {
    @apply w-full flex flex-row items-center justify-between gap-5 cursor-pointer;
    padding-bottom: 10px;
  }

  .tab-title-conteiner {
    @apply flex flex-col
  }

  .tab-title {
    @apply text-main-purple;
    letter-spacing: -0.078px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
  .tab-sub-title {
    @apply text-main-purple;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.078px;
  }

  .toggler {
    svg {
      width: 13px;
      height: 13px;
    }

    &.is-selected {
      svg {
        @apply fill-main-purple;

        * {
          @apply fill-main-purple;
        }
      }
    }
  }

  .separator {
    background: #838da3;
    opacity: 0.2;
    height: 1px;
    width: 100%;
  }

  .content {
    @apply bg-white overflow-hidden w-full;
  }
}
