.mm-pair-chart-footer {
    @apply w-full flex flex-row items-center justify-between gap-3;

    .footer-left {
        @apply flex flex-row items-center gap-2;
    }

    .footer-right {
        @apply flex flex-row items-center gap-3;
    }

    .balance-cell {
        @apply flex flex-row items-center gap-1;
        font-size: 12px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }

        &.inactive {
            color: #aaa;
        }
    }
}