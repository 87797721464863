.mm-cex-wt-bot-organic-task-modal {
    .mm-general-modal__content {
        @apply overflow-hidden flex flex-col;
    }

    form.mm-create-task-form {
        height: 100%;
        width: 500px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 15px;
    
        .form-container {
          @apply flex flex-col gap-3;
        }
    
        .form-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: calc(100% - 100px);
          overflow: auto;
          padding: 0 14px;
        }
    
        .inputs-row {
          @apply flex flex-row items-center justify-between gap-4 w-full;
    
          > div {
            width: calc(50% - 10px);
          }
        }
      }

    .task-actions {
        @apply flex flex-col gap-1;
        flex-shrink: 0;
    }

    .spinner-container {
      @apply self-center justify-self-center;
    }

    .daily-container {
        @apply text-gray-1 text-sm;
        &__daily-volume {
          @apply flex justify-between mt-5 mb-2.5;
        }
        &__daily-free-costs {
          @apply flex justify-between;
        }
      }
}