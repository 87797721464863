.mm-afr-bot-panel__toolbar {
  @apply w-full flex flex-row items-center justify-end;

  &__right {
    @apply flex flex-row items-center;

    button.settings-button {
      @apply gap-1 p-2;
    }
  }
}
