.mm-add-account-modal {
  @apply w-full flex flex-col gap-5;


  &__error {
    @apply text-red-1 font-bold;
    font-size: 15px;
  }
}

.mm-cex-account-modal {
  min-width: 345px;
  min-height: 450px;
}
