.mm-deposit-withdraw-modal-form {
  @apply w-full flex flex-col gap-4;
  width: 500px;

  .mm-deposit-withdraw-modal-token-info {
    @apply w-full;

    &__balance {
      @apply text-gray-1 text-sm;
    }
  }

  .mm-deposit-withdraw-modal-division-type {
    @apply w-full flex flex-col items-start;

    &__min-max {
      @apply w-full flex flex-row items-center gap-3 justify-between;

      .mm-input-container {
        width: 200px;
      }
    }
  }

  button.max-button {
    @apply text-main-purple font-bold p-2 cursor-pointer rounded-xl;
    font-size: 14px;
  }

  .token-total-send-row {
    @apply w-full flex flex-row items-center justify-between gap-2 mt-1;
  }

  .mm-deposit-withdraw-modal-wallets {
    @apply w-full mt-2;

    button.max-button.without-padding {
      @apply p-1;
    }

    &__header {
      @apply w-full flex flex-row items-center justify-between gap-3 px-2;

      span {
        @apply text-sm text-gray-1;
      }
    }

    &__list {
      @apply w-full flex flex-col items-center mt-3 gap-2 px-1;
      max-height: 150px;
      overflow-y: scroll;

      li {
        @apply w-full flex flex-col;

        .wallet-hint {
          @apply w-full flex flex-row items-center justify-between;
        }

        .wallet-error {
          @apply text-red-1 font-bold;
          font-size: 12px;
        }

        div.wallet-item {
          @apply w-full flex flex-row items-center justify-between gap-3 px-5 py-3 bg-light-gray-1;
          border-radius: 30px;
          height: 53px;

          &._disabled {
            background-color: #e2e2e2 !important;
          }

          span.wallet-address {
            @apply text-gray-1 font-bold text-sm;
          }

          &__right {
            @apply flex items-center gap-1;

            input {
              @apply text-main-purple font-bold text-sm border-none outline-none bg-transparent;
              width: 230px;
              text-align: right;
            }
          }
        }

        span.wallet-balance {
          @apply ml-3 text-gray-1;
          font-size: 12px;
        }
      }
    }
  }
}
