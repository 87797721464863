.mm-radio-box {
  input[type='radio'] {
    @apply w-[15px] h-[15px] outline-none;
    accent-color: #5932ea;
  }

  @apply w-full flex flex-row items-center gap-2 cursor-pointer;

  &__text {
    @apply text-gray-1;
    font-size: 14px;
  }
}
