.action-button-cell {
  @apply flex items-center justify-end gap-2 cursor-pointer text-main-purple;

  svg {
    width: 20px;
    height: 20px;
  }
}

.tokens-accounts-cell-wrapper {
  @apply flex gap-2 items-center
}