.mm-general-modal {
  @apply bg-white border-2 border-main-purple border-solid rounded-3xl py-7 px-2 flex flex-col gap-4;
  min-width: 500px;
  max-height: calc(100vh - 40px);
  max-width: 100%;

  &__header {
    @apply w-full flex flex-row items-center justify-between px-5;

    h3 {
      @apply text-main-purple text-xl;
    }

    &__close-button {
      @apply cursor-pointer;
    }
  }

  &__content {
    @apply w-full flex-grow px-5 py-5;
    overflow: auto;
  }

  &__buttons {
    @apply w-full flex flex-col gap-3 mt-3 px-5;
  }
}
