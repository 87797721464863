.mm-connect-dex-wallets-table-loading {
    height: 400px;
    @apply w-full flex items-center justify-center;
}

.mm-connect-dex-wallets-table-container {
    @apply overflow-y-auto mb-5;
    max-height: 400px;
  
    thead {
      position: -webkit-sticky !important;
    }
}