.mm-auth-page-wrapper {
  @apply overflow-hidden relative;
  background: linear-gradient(105.96deg, #5e4dcc 0%, #3f08b6 100%);
  width: 100vw;
  height: 100vh;

  &__content {
    @apply absolute bg-white px-7 py-14;
    top: 50%;
    left: 50%;
    border-radius: 36px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 22px rgba(220, 224, 249, 0.5);

    &__title {
      @apply text-light-purple-1 mb-8 text-center text-2xl;
    }

    &__inner {
      @apply flex flex-col gap-6;
      width: 500px;
    }
  }

  &__holo-top-left {
    @apply absolute top-0 left-0;

    @media screen and (max-width: 1200px) {
      width: 400px;
    }
  }

  &__holo-top-right {
    @apply absolute top-0 right-0;

    @media screen and (max-width: 1200px) {
      width: 210px;
    }
  }

  &__holo-bottom-left {
    @apply absolute bottom-0 left-0;

    @media screen and (max-width: 1200px) {
      width: 210px;
    }
  }

  &__holo-bottom-right {
    @apply absolute bottom-0 right-0;

    @media screen and (max-width: 1200px) {
      width: 400px;
    }
  }
}
