.mm-small-row-list-selector {
  @apply w-fit flex flex-row items-center gap-7;

  &__item {
    @apply p-2 w-[72px] text-gray-1 bg-light-gray-1 font-bold flex items-center justify-center cursor-pointer border-2 border-solid;

    border-radius: 40px;
    font-size: 15px;
  }
}
