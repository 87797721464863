.mm-common-table-toolbar {
  @apply w-full flex flex-row items-center justify-between gap-5 p-5 border-b border-solid border-gray-2 border-opacity-20;

  &__custom-filters {
    @apply flex flex-row items-center gap-2;
  }

  &__custom-actions {
    @apply flex flex-row items-center gap-2;
  }
}
