.mm-panel {
  @apply w-full flex flex-col;

  &__toolbar {
    @apply w-full flex flex-row items-center justify-between bg-white rounded-3xl p-5 cursor-pointer;
    transition: border-radius 1s ease;

    &._opened {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &__title {
      @apply flex flex-row items-center gap-2;

      &__icon {
        * {
          transition: all 0.3s ease;
        }
      }

      &__inner {
        @apply font-bold text-sm text-gray-1;
        transition: color 0.3s ease;

        &._opened {
          @apply text-main-purple;
        }


        &__title-container {
          @apply flex justify-between w-full
        }
      }
    }

    &__custom-header {
      @apply flex flex-row items-center justify-between mx-3;
      flex: 1 0 auto;
    }

    &__open-icon {
      svg {
        transition: all 0.3s ease;
      }
    }
  }

  &__content {
    @apply bg-white overflow-hidden;
    border: 0px solid transparent;
    transition: border 0.5s ease;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    &._opened {
      @apply border-t border-solid border-light-gray-2 border-opacity-20;
    }
  }

  &._overflow-disable {
    .mm-panel__content {
      overflow: visible !important;
    }
  }

  &._table-container {
    .mm-panel__content {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}

.mm-panel {
  &__toolbar {

    .switcher-arrow-container {
      @apply flex gap-6 items-center
    }
  }
}

.mm-panel {
  &__toolbar {

    .switcher-arrow-container {
      @apply flex gap-6 items-center
    }
  }
}