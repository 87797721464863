.mm-application-layout {
  @apply w-[100vw] h-[100vh] overflow-hidden flex flex-row items-start bg-light-gray-1 relative;

  &__main__holder {
    @apply h-[100vh] overflow-hidden relative;
    width: calc(100vw - 16rem);
  }

  &__main {
    @apply overflow-scroll flex flex-col w-full;
    max-height: 100vh;
  }

  &__content {
    @apply px-3 pt-3 pb-6;
    margin: 0 4px;
    margin-bottom: 8px;
    width: calc(100% - 8px);
    flex-grow: 1;
  }
}
