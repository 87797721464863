.mm-wt-bot-panel__timeframe-controller {
  width: 200px;

  .mm-dropdown__button {
    padding: 8px 16px;

    &._opened {
      border-radius: 22px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}
