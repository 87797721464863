.mm-withdraw-modal-warning {
  @apply bg-light-gray-1 w-full rounded-xl p-2 flex flex-col items-start gap-1;
  margin: 0 auto;

  .warning-title {
    @apply text-red-1 text-sm font-bold;
  }

  .warning-text {
    font-size: 13px;
  }
}
