.mm-checkbox-input {
  display: none;
}

.mm-checkbox {
  @apply w-4 h-4 flex flex-col items-center justify-center border-gray-2 rounded cursor-pointer;
  border-width: 1px;

  svg {
    max-width: calc(100% - 2px);
    max-height: calc(100% - 2px);
  }
}
