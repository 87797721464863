.mm-buy-sell-bot-toolbar {
  @apply w-full flex flex-row items-center justify-between px-5 py-3 border-b border-opacity-20 border-gray-2;

  &__left {
    @apply flex flex-row items-center gap-8;
  }

  &__right {
    @apply grid grid-flow-col;

    button {
      @apply p-2;
    }

    button.settings-button {
      @apply gap-1;
    }
  }

  .title {
    @apply font-bold text-gray-1 text-sm;
    line-height: 16px;
  }

  .indicators {
    @apply flex flex-row items-center gap-4;
  }

  .indicator {
    @apply flex flex-row items-center gap-1;

    &__caption {
      @apply text-sm font-normal text-gray-1;
    }
  }

  .round {
    @apply rounded-full;
    width: 8px;
    height: 8px;

    &.round-green {
      @apply bg-light-green-1;
    }

    &.round-purple {
      @apply bg-main-purple;
    }

    &.round-gray {
      @apply bg-gray-1;
    }
  }
}
