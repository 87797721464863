.mm-wash-trading-bot-toolbar {
  @apply w-full flex flex-row items-center justify-between px-5 py-3;

  &__left {
    width: 300px;
  }

  &__right {
    button.settings-button {
      @apply gap-1 p-2;
    }
  }
}
