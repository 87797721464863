.mm-wallet-button {
  width: auto;
  position: relative;

  .mm-wallet-button-icon {
    svg {
      width: 18px;
    }
  }

  .mm-wallet-button-wallet-address {
    font-size: 13px;
    @apply font-bold;
  }

  .mm-wallet-button-menu {
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    z-index: 5;

    button {
      @apply bg-white;
    }
  }
}
