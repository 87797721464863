.tool-tip-wrapper {
  .indicator {
    background: #7f91bb5f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    cursor: pointer;

    span {
      @apply text-main-purple font-bold;
      font-size: 10px;
    }
  }

  .mm-table-token-tooltip {
    max-width: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }
  .transparent-tooltip-indicator {
    @apply bg-transparent
  }
}