.mm-wash-algorithm-bot-row-extended-info {
  @apply grid gap-5 overflow-hidden;

  div.line-separator {
    @apply bg-gray-2 w-full opacity-20;
    height: 0.5px;
  }

  .sections {
    @apply flex flex-col;
    gap: 10px;
  }

  .section-title {
    @apply text-main-purple font-semibold text-base;
    letter-spacing: -0.32px;
  }

  .section {
    @apply grid gap-6 align-top;

    grid-template-columns: 1fr 1fr 1fr;

    span {
      font-size: 13px;
      line-height: 18px;
      height: 36px;
    }

    span.thin {
      letter-spacing: -0.078px;
    }

    span.bold {
      letter-spacing: -0.32px;
      font-weight: 600;
    }
  }



  .extended-row__footer {
    @apply flex flex-row justify-end items-center gap-5 font-bold;

    .edit-task-button {
      @apply text-main-purple w-48 /*py-3.5 px-5 */;
      padding: 13px 20px !important;
      font-size: 13px !important;
      line-height: 16px !important;
    }

    .delete-task-button {
      @apply text-red-1 border-red-1 w-48;
      padding: 13px 20px !important;
      font-size: 13px !important;
      line-height: 16px !important;
    }

    span {
      font-size: 13px;
    }
  }
}
