.mm-withdraw-whitelist-modal {
  @apply w-full flex flex-col items-center justify-center gap-3;

  .whitelist-empty {
    @apply flex flex-col gap-3 items-center;

    span {
      @apply font-normal text-gray-1;
      font-size: 15px;
    }
  }

  .copy-icon,
  .close-icon {
    @apply flex justify-end items-center cursor-pointer p-1;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .whitelist-actions {
    @apply mt-4 w-full flex flex-col;
  }

  .table-actions {
    @apply flex flex-row items-center gap-1 justify-end;

    .accept-action {
      @apply p-1 text-main-purple font-medium cursor-pointer;
      font-size: 12px;
    }
  }

  .wallet-cell {
    @apply flex flex-row items-center;
    gap: 2px;

    .wallet-icon-container {
      @apply p-1 cursor-pointer relative;
    }
  }
}

.withdrawal-whitelist-confirmations-list {
  background-color: white;
  z-index: 102;
  box-shadow: 0px 6px 13px 0px #b8c2fa80;
  border-radius: 20px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .confirmation-person {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    width: 200px;
  }
}
